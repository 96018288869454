<template>
  <page-main
    title="Degree Information"
    :aside="[
      'Record your degree type (Bachelor of Arts (BA), Bachelor of Science (BS), Masters of Business Administration (MBA), etc) and your area of study (Economics, English, Mechanical Engineering, Business, etc).',
      'If it\'s relevant for your field, record test scores as well - for example, a GRE or LSAT score.',
    ]"
    nextText="Awards and Activities"
    :buttonTabIndex="nextti"
    eventName="nav"
    @nav="nav('/EducationEntryAwards/' + key)"
    @save="save()"
  >
    <v-container>
      <v-row>
        <v-col cols="12" class="r-col">
          <r-single-select
            v-model="degreeName"
            label="Degree Type"
            tabindex="1"
            autofocus
            :items="baseDegrees"
          ></r-single-select>
        </v-col>
        <v-col cols="12" class="r-col">
          <r-text-field
            v-model="degreeArea"
            label="Area of study"
            tabindex="2"
          ></r-text-field>
        </v-col>
        <v-col cols="12" class="r-col">
          <r-text-field
            v-model="minors"
            label="Minor(s)"
            tabindex="3"
          ></r-text-field>
        </v-col>
        <v-col cols="12" class="r-col">
          <r-dual-list
            label="Tests:"
            labelOne="Test"
            labelTwo="Score"
            buttonLabel="Add Test"
            v-model="educationTests"
            tabindex="5"
            dataNameOne="testName"
            dataNameTwo="testScore"
            keyName="testKey"
          ></r-dual-list>
        </v-col>
      </v-row>
    </v-container>
  </page-main>
</template>

<script>
import { mapFields } from "vuex-map-fields";
import { mapActions, mapGetters } from "vuex";
import * as disp from "../utility/display";

// @ is an alias to /src
export default {
  name: "EducationEntryDegree",
  data: () => ({}),
  components: {},
  mounted() {
    this.key = this.$route.params.key;
    let pageKey = this.$route.params.key;
    if (this.key != pageKey) {
      if (!this.doesKeyExist(this.pageKey)) {
        this.createEntry({ key: this.pageKey });
      }
      this.key = this.pageKey;
    }
  },
  methods: {
    save() {
      this.educationTests = [
        ...this.educationTests.filter(
          (t) => !disp.IsNullorWhitespace(t.testName)
        ),
      ];
      this.saveEducation();
    },
    ...mapActions("education", ["saveEducation"]),
    nav(routerPath) {
      this.$router.push({ path: routerPath });
    },
  },
  computed: {
    ...mapGetters("education", ["doesKeyExist", "baseDegrees"]),
    ...mapFields("education", {
      key: "key",
      educationTests: "educationTests",
      degreeName: "educationDegree.degreeName",
      degreeArea: "educationDegree.degreeArea",
      degreeDisplay: "educationDegree.display",
      minors: "educationDegreeMinor.minors",
      minorDisplay: "educationDegreeMinor.display",
    }),
    nextti() {
      let ti = this.educationTests.length * 3 + 5; // tab index for next button
      return ti.toString();
    },
  },
};
</script>
